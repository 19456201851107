import React from "react";
import { Link } from "react-router-dom";

export const NotFoundPage = () => {
  return (
    <div
      style={{
        backgroundColor: "var(--background-color)",
        height: "100vh",
        margin: "0",
      }}
    >
      <div
        style={{
          textAlign: "center",
          paddingTop: "20px",
        }}
      >
        <h1>404 - Page Not Found</h1>
        <p>The page you are looking for does not exist.</p>
        <p>
          Return to <Link to="/">Home</Link>.
        </p>
      </div>
    </div>
  );
};
