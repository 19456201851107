import React, { useState } from "react";
import { AV_MODAL } from "../../shared/AV_MODAL";
import { AV_FILE_VIEWER } from "../../shared/AV_FILE_VIEWER";
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  List,
  TabPane,
  Tab,
} from "semantic-ui-react";

const ViewTransactionModal = ({ isOpen, onClose, transactionData, files }) => {
  const [viewerOpen, setViewerOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState({ url: "", name: "" });

  const handleFileClick = (file) => {
    setSelectedFile(file);
    setViewerOpen(true);
  };

  const closeViewer = () => {
    setViewerOpen(false);
  };
  // Filter files based on type
  // const documents = files.filter((file) => file.type.includes("pdf"));
  // const images = files.filter((file) =>
  //   ["image/jpeg", "image/png", "image/webp"].includes(file.type)
  // );

  // Filter files based on type
  const documents = files
    ? files.filter((file) => file.type.includes("pdf"))
    : [];
  const images = files
    ? files.filter(
        (file) =>
          ["image/jpeg", "image/png", "image/webp"].indexOf(file.type) !== -1
      )
    : [];

  const panes = [
    {
      menuItem: "Documents",
      render: () =>
        documents.length > 0 ? (
          <List divided>
            {documents.map((doc, index) => (
              <List.Item key={index}>
                <List.Content floated="right">
                  <Button
                    icon="download"
                    size="mini"
                    onClick={() => window.open(doc.url, "_blank")}
                  />
                </List.Content>
                <List.Icon
                  name="file pdf outline"
                  color="grey"
                  size="large"
                  verticalAlign="middle"
                />
                <List.Content>
                  <List.Header
                    as="a"
                    onClick={() =>
                      handleFileClick({ url: doc.url, name: doc.name })
                    }
                  >
                    {doc.name}
                  </List.Header>
                </List.Content>
              </List.Item>
            ))}
          </List>
        ) : (
          <Header as="h5" textAlign="center">
            No documents available for this transaction.
          </Header>
        ),
    },
    {
      menuItem: "Images",
      render: () =>
        images.length > 0 ? (
          <List divided relaxed style={{ width: "100%" }}>
            {images.map((image, index) => (
              <List.Item key={index}>
                <List.Content floated="right">
                  <Button
                    icon="download"
                    size="mini"
                    onClick={() => window.open(image.url, "_blank")}
                  />
                </List.Content>
                <List.Icon
                  name="file pdf outline"
                  color="grey"
                  size="large"
                  verticalAlign="middle"
                />
                <List.Content>
                  {/* <img
                    src={image.url}
                    alt={image.name}
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      marginBottom: "10px",
                    }}
                    onClick={() =>
                      handleFileClick({ url: image.url, name: image.name })
                    }
                  /> */}
                  <List.Header
                    as="a"
                    onClick={() =>
                      handleFileClick({ url: image.url, name: image.name })
                    }
                  >
                    {image.name}
                  </List.Header>
                </List.Content>
              </List.Item>
            ))}
          </List>
        ) : (
          <Header as="h5" textAlign="center">
            No images available for this transaction.
          </Header>
        ),
    },
  ];

  if (!transactionData || !files) {
    return null;
  }
  return (
    <>
      <AV_MODAL
        title="View Transaction"
        visible={isOpen}
        handleCancel={onClose}
        size="large"
        scrolling={true}
      >
        {/* Display transaction details */}

        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <List.Item>
                <Header as="h4">Project Name</Header>
                {transactionData.project}
              </List.Item>
              <Divider />
            </Grid.Column>
            <Grid.Column>
              <List.Item>
                <Header as="h4">Amount (N$)</Header>
                {transactionData.amount}
              </List.Item>
              <Divider />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              {" "}
              <List.Item>
                <Header as="h4">Transaction Type</Header>
                {transactionData.type}
              </List.Item>
              <Divider />
            </Grid.Column>
            <Grid.Column>
              {" "}
              <List.Item>
                <Header as="h4">Created Date</Header>
                {new Date(transactionData.createdAt).toLocaleString()}
              </List.Item>
              <Divider />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={2}>
            <Grid.Column>
              <List.Item>
                <Header as="h4">Notes:</Header>

                {transactionData.notes}
              </List.Item>

              <Divider />
            </Grid.Column>
            <Grid.Column>
              <List.Item>
                <Header as="h4">Created By</Header>
                {transactionData.user.email}
              </List.Item>
              <Divider />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={1}>
            <Grid.Column>
              {" "}
              <List.Item>
                <Header as="h4">Media</Header>
                <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
              </List.Item>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </AV_MODAL>
      <AV_FILE_VIEWER
        pdf={selectedFile}
        previewVisible={viewerOpen}
        closeModal={closeViewer}
      />
    </>
  );
};

export default ViewTransactionModal;
