/**
 * date.util.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright (c) 2024
 * All rights reserved
 */

import { DateTime } from 'luxon';
import { DATE_SHORT } from '../config/constants';

/**
 * Formats the JavaScript date object into a
 * Full Date string using locale
 * @param {Date} date - JavaScript Date Object
 */
export function dateFormat(date) {
    return parseDate(date).toLocaleString(DateTime.DATE_FULL);
}

/**
 * Formats the JavaScript date object into a 24 hour Time string using locale
 * @param {Date|String} date - JavaScript Date Object or ISO date string
 */
export function timeFormat(date) {
    return parseDate(date).toLocaleString(DateTime.TIME_24_SIMPLE);
}

/**
 * Formats the JavaScript date object into a DATETIME object using locale
 * @param {Date} date - JavaScript Date Object
 */
export function dateTimeFormat(date) {
    return parseDate(date).toLocaleString(DateTime.DATETIME_MED);
}

export function shortDate(date) {
    return parseDate(date).toFormat(DATE_SHORT);
}

export function parseDate(date) {
    let convertedDate = null;
    switch (typeof date) {
        case 'object':
            convertedDate = DateTime.fromJSDate(date);
            break;
        default:
            convertedDate = DateTime.fromISO(date);
            break;
    }
    return convertedDate;
}

/**
 * Sorts an array of objects by date using the specified key
 * @param {string} key
 */

export function sortByDate(key) {
    return function (a, b) {
        if (new Date(a[key]) < new Date(b[key])) return -1;
        if (new Date(a[key]) > new Date(b[key])) return 1;
        return 0;
    };
}
/**
 * Sorts an array of objects by date by latest first using the specified key
 * @param {string} key
 */
export function sortByLatestDate(key) {
    return function (a, b) {
        return new Date(b[key]) - new Date(a[key]);
    };
}

export function value(value, unit) {
    unit = Math.abs(value) > 1 ? `${unit}s` : unit;
    const tense = value <= 0 ? 'to go' : 'ago';
    if (unit === 'days' && value === 1) {
        return 'Yesterday';
    }
    return `${Math.abs(value)} ${unit} ${tense}`;
}

export function valuee(value, unit) {
    unit = Math.abs(value) > 1 ? `${unit}s` : unit;
    if (unit === 'days' && value === 1) {
        return 'Yesterday';
    }
    return `${Math.abs(value)} ${unit}`;
}

export function durationChecks(endTime, startTime) {
    const start = parseDate(new Date(startTime));
    const end = parseDate(new Date(endTime));

    const duration = end
        .diff(start, ['seconds', 'minutes', 'hours', 'days', 'weeks', 'months', 'years'])
        .toObject();

    if (duration.years !== 0) {
        return valuee(duration.years, 'year');
    } else if (duration.months !== 0) {
        return valuee(duration.months, 'month');
    } else if (duration.weeks !== 0) {
        return valuee(duration.weeks, 'week');
    } else if (duration.days !== 0) {
        return valuee(duration.days, 'day');
    } else if (duration.hours !== 0) {
        return valuee(duration.hours, 'hour');
    } else if (duration.minutes !== 0) {
        return valuee(duration.minutes, 'minute');
    }
    return parseInt(duration.seconds ? duration.seconds.toString() : ''), 'secs';
}

export function duration(endDate, startDate) {
    const start = parseDate(new Date(startDate));
    const end = parseDate(new Date(endDate));

    const duration = end
        .diff(start, ['seconds', 'minutes', 'hours', 'days', 'weeks', 'months', 'years'])
        .toObject();

    if (duration.years !== 0) {
        return value(duration.years, 'year');
    } else if (duration.months !== 0) {
        return value(duration.months, 'month');
    } else if (duration.weeks !== 0) {
        return value(duration.weeks, 'week');
    } else if (duration.days !== 0) {
        return value(duration.days, 'day');
    } else if (duration.hours !== 0) {
        return value(duration.hours, 'hour');
    } else if (duration.minutes !== 0) {
        return value(duration.minutes, 'minute');
    }

    return value(parseInt(duration.seconds ? duration.seconds.toString() : ''), 'sec');
}
