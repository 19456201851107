import React, { useState, useEffect } from "react";
import {
  Grid,
  Menu,
  Segment,
  Icon,
  Header,
  Breadcrumb,
  Button,
} from "semantic-ui-react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import Toolbar from "../components/common/Toolbar";
import { useAuth } from "../context/AuthContext";
import Swal from "sweetalert2";
import { VaultTable } from "../components/common/VaultTable";
import CreateProjectModal from "../components/common/projects/CreateProjectModal";
import ProjectDetailsModal from "../components/common/projects/ProjectDetailsModal";
import { dateFormat, dateTimeFormat } from "../helpers/date.util";
import { ENV } from "../config/env.config";
import { APIService } from "../services";

const baseUrl = ENV.baseUrl;

export const Projects = () => {
  const { userData } = useAuth();
  const currentUserId = userData ? userData._id : null;

  const [projects, setProjects] = useState([]);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [searchResults, setSearchResults] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("view"); // 'view' or 'edit'
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  // APIService instance
  const projectsService = new APIService(`${baseUrl}/projects`, "projects");

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    setLoading(true);
    try {
      const fetchedProjects = await projectsService.$fetch();
      setProjects(fetchedProjects);
      setSearchResults(fetchedProjects);
    } catch (error) {
      console.error("Error fetching projects:", error);
    } finally {
      setLoading(false);
    }
  };

  const openModal = (projectId, mode) => {
    setSelectedProjectId(projectId);
    setModalMode(mode);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmDelete = async (project) => {
    setProjectToDelete(project._id);
    try {
      await projectsService.$delete(project._id);
      fetchProjects(); // Refetch the projects after deletion
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: "success",
        title: "Project deleted successfully",
      });
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };

  const handleSearch = (searchTerm) => {
    const filteredResults = projects.filter((project) =>
      project.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredResults);
  };

  return (
    <>
      <div className="breadcrumb-container">
        <Breadcrumb>
          <Breadcrumb.Section as={Link} to="/">
            <Icon name="home" />
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section active>Projects</Breadcrumb.Section>
        </Breadcrumb>
      </div>
      <Grid style={{ padding: "30px", fontFamily: "Jost, sans-serif" }}>
        <Grid.Column width={2}>
          <Menu fluid vertical tabular>
            <Menu.Item
              name="projects"
              active={location.pathname === "/projects"}
            >
              <Icon name="clipboard list" />
              <p style={{ fontFamily: "Jost, sans-serif", fontWeight: "400" }}>
                Projects
              </p>
            </Menu.Item>
            <Menu.Item
              name="transactions"
              as={Link}
              to="/transactions"
              active={location.pathname === "/transactions"}
            >
              <Icon name="exchange" />
              <p style={{ fontFamily: "Jost, sans-serif", fontWeight: "400" }}>
                Transactions
              </p>
            </Menu.Item>
            <Menu.Item
              name="users"
              as={Link}
              to="/users"
              active={location.pathname === "/users"}
            >
              <Icon name="users" />
              <p style={{ fontFamily: "Jost, sans-serif", fontWeight: "400" }}>
                Users
              </p>
            </Menu.Item>
          </Menu>
        </Grid.Column>
        <Grid.Column stretched width={14}>
          <Segment>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Header
                as="h2"
                style={{ fontFamily: "Jost, sans-serif", fontWeight: "500" }}
              >
                Autovault Projects
              </Header>
              <Toolbar
                onRefresh={fetchProjects}
                onSearch={handleSearch}
                isTransactions={false} // Pass false to indicate it's not the Transactions component
                modalComponent={<CreateProjectModal />} // Pass the CreateProjectModal as the modal component
              />
            </div>
            <VaultTable
              data={searchResults}
              title="Projects"
              columns={[
                {
                  title: "Project Name",
                  key: "name",
                  dataIndex: "name",
                  searchable: true,
                  sortable: true,
                },
                {
                  title: "Status",
                  key: "status",
                  dataIndex: "status",
                },
                {
                  title: " Created",
                  key: "created",
                  dataIndex: "createdAt",
                  render: (createdAt) =>  dateTimeFormat(new Date(createdAt)),
                },
                { title: "User ID", key: "user", dataIndex: "user" },
              ]}
              rowKey="_id"
              scroll={{ x: 100, y: 1000 }}
              paginationSettings={{
                position: "bottomRight",
                current: currentPage,
                pageSize: itemsPerPage,
                total: searchResults.length,
              }}
              loading={loading}
              refetch={fetchProjects}
              confirmDelete={handleConfirmDelete}
              refreshing={loading}
              updateState={({ view, mode, selectedItem }) => {
                openModal(selectedItem._id, mode);
              }}
            />
          </Segment>
        </Grid.Column>
      </Grid>
      <ProjectDetailsModal
        onClose={() => setIsModalOpen(false)}
        visible={isModalOpen}
        projectId={selectedProjectId}
        mode={modalMode}
      />
    </>
  );
};

export default Projects;
