/**
 * env.config.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright (c) 2024
 * All rights reserved
 */
const { REACT_APP_API_URL, REACT_APP_LOGGING, REACT_APP_ENCRYPT_DATA } = process.env;

export const ENV = {
    author: 'Nestor Nathingo <nessynathingo@gmail.com>,Erastus Nathingo <contact@erassy.com>',
    logging: REACT_APP_LOGGING === 'TRUE',
    baseUrl: REACT_APP_API_URL,
    enableGrid: false,
    REACT_APP_ENCRYPT_DATA
};
