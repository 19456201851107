import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Form as SemanticForm, Grid } from "semantic-ui-react";
import axios from "axios";
import Swal from "sweetalert2";
import { AV_INPUT } from "../../shared/AV_INPUT";
import { AV_DROPDOWN } from "../../shared/AV_DROPDOWN";
import {AV_NOTIFY} from "../../shared/AV_NOTIFY";
import { ENV } from "../../../config/env.config";
import { useAuth } from "../../../context/AuthContext";

const baseUrl = ENV.baseUrl;

// Validation Schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  status: Yup.string().required("Project status is required"),
});

// Status for dropdown
const projectStatusOptions = [
  { key: "InProgress", text: "In Progress", value: "In Progress" },
  { key: "Completed", text: "Completed", value: "Completed" },
];

const CreateProjectForm = ({ isNewProject, onClose, initialData }) => {

  // Retrieve userId from AuthContext
  const { userData } = useAuth();
  const currentUserId = userData ? userData._id : null;

  const handleSubmit = async (values, { setSubmitting }) => {
    const apiUrl = isNewProject ? `${baseUrl}/projects` : `${baseUrl}/projects/${initialData._id}`;
    const method = isNewProject ? axios.post : axios.put;
  
    try {
      await method(apiUrl, { ...values });
      AV_NOTIFY({
        title: `Project ${isNewProject ? 'added' : 'updated'}`,
        // content: error.message,
        type: "success",
        duration: 10,
      });
  
      onClose(); // Close modal and optionally refresh the list or details view
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "Something went wrong!",
      });
    } finally {
      setSubmitting(false);
    }
  };
  
  return (
    <Formik
      initialValues={{
        name: initialData.name || "",
        status: initialData.status || "",
        user: currentUserId
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({
        errors,
        touched,
        handleChange,
        handleBlur,
        values,
        setFieldTouched,
        setFieldValue,
        handleSubmit,
      }) => (
        <SemanticForm onSubmit={handleSubmit}>
          <Grid stackable>
            <Grid.Row columns={2}>
              <Grid.Column>
                <AV_INPUT
                  name="name"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  label="Name"
                  value={values.name}
                  errors={errors}
                  touched={touched}
                />
              </Grid.Column>
              <Grid.Column>
                <AV_DROPDOWN
                  name="status"
                  value={values.status || ""}
                  onChange={(e, { value }) => setFieldValue("status", value)}
                  onBlur={(e, { name, value }) => setFieldTouched(name, value)}
                  label="Project Status"
                  search={true}
                  options={projectStatusOptions}
                  errors={errors}
                  touched={touched}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Button type="submit" primary floated="right">
                {isNewProject ? "Create" : "Update"}
              </Button>
              <SemanticForm.Field></SemanticForm.Field>
            </Grid.Row>
          </Grid>
        </SemanticForm>
      )}
    </Formik>
  );
};

export default CreateProjectForm;
