/**
 * AV_NOTIFY.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2024
 * All rights reserved
 */

import { notification } from 'antd';
const TOASTDURATION = 10;

export const AV_NOTIFY = ({
	title = 'Notification',
	type,
	content = '',
	duration = TOASTDURATION,
}) => {
	notification[type]({
		message: title,
		description: content,
		placement: 'topRight',
		duration,
		style: {
			background: '#0c3a51',
			zIndex: 4000,
			color: 'white',
		},
		top: 100,
	});
};
