import { ENV } from '../config/env.config';

const LOGGING_ENABLED = ENV.logging === true;

export const Logger = {
    warn: (...warnings) => {
        if (LOGGING_ENABLED) {
            console.warn('Warning:', ...warnings);
        }
    },

    info: (...info) => {
        if (LOGGING_ENABLED) {
            console.info('Info:', ...info);
        }
    },
    log: (...data) => {
        if (LOGGING_ENABLED) {
            console.info(...data);
        }
    },
    error: (...errors) => {
        if (LOGGING_ENABLED) {
            console.error(...errors);
        }
    },
};
