/**
 * ValicationSchema.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2024
 * All rights reserved
 */


import * as Yup from 'yup';
export const TransactionValidationSchema = Yup.object().shape({
	project: Yup.string().required('Project is required'),
	type: Yup.string().required('Transaction type is required'),
	notes: Yup.string(),
	amount: Yup.number(),
});