/**
 * misc.util.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright (c) 2024
 * All rights reserved
 */

import { sortByname } from "./array.util";

/**
 * Returns true if all items in the array are defined
 * @param {Array} items
 */
export function defined(items) {
    return items.every((item) => item !== undefined);
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeFirstLetters(stringText) {
    stringText = stringText.replaceAll('_', ' ');
    return stringText
        .replace(/[_\s]+/g, ' ')
        .trim()
        .toLowerCase()
        .replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase());
}

// this function allows us to const pluck = property => element => element[property]

export const pluck = (property) => (element) => element[property];

/**
 *
 * @param {Object} obj object to test emptyness for
 * @returns {Boolean} returns true if object is empty and false if object is not empty
 */
export function isObjectEmpty(obj) {
    for (const i in obj) return false;
    return true;
}
/**
 * @name sortArrayByProperty
 * @param {Array} array array to be sorted
 * @param {String} sortProperty array sort property
 * @returns {Array} returns array sorted by property
 */
export function sortArrayByProperty(array, sortProperty) {
    return array.sort((a, b) => {
        if (a[sortProperty] < b[sortProperty]) {
            return -1;
        }
        if (a[sortProperty] > b[sortProperty]) {
            return 1;
        }
        return 0;
    });
}

export function dropdownMapper(collection) {
    const sortedCollection = sortByname(collection);
    return sortedCollection.map((item) => {
        return { key: item._id, text: item.name, value: item._id };
    });
}