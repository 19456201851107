/**
 * AV_ERRORLABEL.jsx
 *
 * @author Erastus Nathingo <erastus.nathingo@standardbank.com.na>
 * @copyright (c) 2024
 * All rights reserved
 */
import React from 'react';
import { Typography } from 'antd';
const { Text } = Typography;

export function AV_ERRORLABEL(props) {
    const { children, style, ...rest } = props;
    return (
        <Text strong style={{ ...style, color: 'red', fontSize: '11px' }} {...rest}>
            {children}
        </Text>
    );
}
