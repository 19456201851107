import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { Button, Form, Icon } from "semantic-ui-react";
import { Steps, theme, Spin } from "antd";
import { useAuth } from "../../../context/AuthContext";
import { TransactionValidationSchema } from "./ValicationSchema";

import { AV_INPUT } from "../../shared/AV_INPUT";
import { AV_DROPDOWN } from "../../shared/AV_DROPDOWN";
import { AV_TEXTAREA } from "../../shared/AV_TEXTAREA";
import {
  TransactionsService,
  TransactionTypesService,
  SessionService,
  ProjectsService,
} from "../../../services/index";
import { Logger, dropdownMapper } from "../../../helpers/index";
import { merge } from "rxjs";
import FileUploader from "../FileUploader";
import { AV_NOTIFY } from "../../shared/AV_NOTIFY";

const { Step } = Steps;

const transactionsService = TransactionsService.create();
const transactionTypeService = TransactionTypesService.create();
const projectsService = ProjectsService.create();

const session = SessionService.session;
//subscriptions
let initialSubscription = session;
const INITIAL_FORM_STEP = 0;

const CreateEditTransactionForm = ({
  initialData = {
    project: "",
    type: "",
    notes: "",
    amount: "",
  },
  project,
  mode = "create",
  handleCancel,
}) => {
  console.log("Project: ", project);
  const { token } = theme.useToken();
  const { userData } = useAuth();
  const userId = userData ? userData._id : null;

  const [activeStep, setActiveStep] = useState(INITIAL_FORM_STEP);
  // const [fileList, setFileList] = useState([]);
  // const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState();
  const [transactionTypes, setTransactionTypes] = useState(undefined);
  const [projects, setProjects] = useState(undefined);
  const [currentTransaction, setCurrentTransaction] = useState({
    type: "",
    project: project?._id,
    amount: 0,
    notes: "",
    user: userId,
  });

  const contentStyle = {
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusSM,
    border: `1px dashed ${token.colorBorder}`,
    padding: 15,
  };

  useEffect(() => {
    // Fetch existing files from your API if needed
    getData();
    return function cleanup() {
      initialSubscription && initialSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (mode === "edit" && initialData) {
      setCurrentTransaction(initialData);
    }
  }, [initialData, mode]);

  const getData = () => {
    setLoading(true);
    initialSubscription = merge(
      projectsService.get(),
      transactionTypeService.get()
    ).subscribe(prepareData, (error) => {
      Logger.log("Resources Get Error: ", error);
      setLoading(false);
    });
  };

  const prepareData = (values) => {
    if (values.data) {
      let { data } = values;
      const { key } = values;

      if (key === "projects") {
        setProjects(dropdownMapper(data));
      }
      if (key === "transaction-types") {
        setTransactionTypes(dropdownMapper(data));
      }
    }

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  /**
   *
   * @param {Object} actions formik form actions
   */
  const successResponse = (actions, data) => {
    const response = Array.isArray(data) ? data[0] : data;
    console.log("Transaction Response: ", response);
    AV_NOTIFY({
      title: `SUCCESS`,
      content: "Please Upload required documents!",
      type: "success",
      duration: 10,
    });

    transactionsService.fetchAndStore();
    // create submission
    setCurrentTransaction(response);
    setTimeout(() => {
      actions.setSubmitting(false);

      setLoading(false);
      setActiveStep(1);
    }, 1000);
  };

  const errorResponse = (error, actions) => {
    Logger.log("Transaction  Error: ", error);
    AV_NOTIFY({
      title: `Oops! Something went wrong!`,
      content: error.message,
      type: "error",
      duration: 10,
    });
    actions.setSubmitting(false);
    setLoading(false);
  };

  /**
   * @description sends the permit application form to the API
   * @param {Object} values permit form values
   * @param {Object} actions formik actions
   */
  const handleCreateOrEditTransaction = (values, actions) => {
    transactionsService.create(values).subscribe(
      (data) => {
        successResponse(actions, data);
      },
      (error) => errorResponse(error, actions)
    );
  };

  const steps = [
    {
      title: "Transaction Details",
      icon: <Icon name="info" />,
      index: 0,
      description: "Fill in the transaction details",
      content() {
        return (
          <Formik
            initialValues={{
              ...initialData,

              user: userId,
            }}
            validationSchema={TransactionValidationSchema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);

              if (mode === "edit") {
                // Assuming you have an edit function in your transactionsService
                transactionsService
                  .edit(currentTransaction._id, values)
                  .subscribe(
                    (data) => successResponse(actions, data),
                    (error) => errorResponse(error, actions)
                  );
              } else {
                handleCreateOrEditTransaction(values, actions);
              }
              // handleCreateOrEditTransaction(values, actions);
            }}
          >
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              values,
              setFieldTouched,
              setFieldValue,
              handleSubmit,
              isSubmitting,
            }) => {
              return (
                <Spin
                  spinning={loading || isSubmitting}
                  tip={loading ? "Fetching Resources." : "Submitting..."}
                >
                  <Form>
                    <Form.Group widths="equal">
                      <AV_DROPDOWN
                        required={true}
                        name="project"
                        label="Project"
                        value={values.project}
                        search={true}
                        onBlur={(e, { name, value }) =>
                          setFieldTouched(name, value)
                        }
                        onChange={(e, { name, value }) =>
                          setFieldValue(name, value)
                        }
                        // disabled={currentTransaction.project !== undefined}
                        errors={errors}
                        touched={touched}
                        options={projects}
                      />

                      <AV_DROPDOWN
                        required={true}
                        name="type"
                        label="Transaction Type"
                        value={values.type}
                        search={true}
                        onBlur={(e, { name, value }) =>
                          setFieldTouched(name, value)
                        }
                        onChange={(e, { name, value }) =>
                          setFieldValue(name, value)
                        }
                        errors={errors}
                        touched={touched}
                        options={transactionTypes}
                      />
                    </Form.Group>

                    <Form.Group widths="equal">
                      <AV_INPUT
                        name="amount"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        label="Amount"
                        type="number"
                        value={values.amount}
                        errors={errors}
                        touched={touched}
                      />
                      <AV_TEXTAREA
                        name="notes"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        label="Notes"
                        value={values.notes}
                        errors={errors}
                        touched={touched}
                      />
                    </Form.Group>

                    <Button.Group floated="right">
                      <Button
                        loading={isSubmitting}
                        positive
                        type="submit"
                        onClick={handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    </Button.Group>

                    <br />
                  </Form>
                </Spin>
              );
            }}
          </Formik>
        );
      },
    },
    {
      title: "Upload Files",
      icon: <Icon name="file" />,
      index: 1,
      description: "Upload any files related to the transaction",
      content() {
        return (
          <div>
            <FileUploader transaction={currentTransaction} />
            <Form style={{ padding: "2%" }}>
              <Button.Group floated="right">
                <Button onClick={handleCancel}>DONE</Button>
              </Button.Group>
            </Form>
          </div>
        );
      },
    },
  ];
  const handleNextClick = () => {
    setActiveStep(activeStep + 1);
  };

  return (
    <div>
      <Steps
        current={activeStep}
        onChange={handleNextClick}
        className="site-navigation-steps bottom"
      >
        {steps.map((item) => (
          <Step
            key={item.title}
            title={item.title}
            // icon={item.icon}
            description={item.description}
            disabled={activeStep !== item.index}
          />
        ))}
      </Steps>

      <br />
      <br />
      <br />
      <div style={contentStyle}>
        {steps[activeStep].content()}
        <br />
      </div>
    </div>
  );
};

export default CreateEditTransactionForm;
