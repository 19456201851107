/**
 * transactions.service.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2024
 * All rights reserved
 */

import { APIService } from "../api/index";

let instance;

export class TransactionsService extends APIService {
  constructor() {
    super("transactions", "transactions");
  }
  static create() {
    if (!instance) {
      instance = new TransactionsService();
    }
    return instance;
  }

  async $get() {
    const urlWithParams = `${this.url}?populate=type&populate=project&populate=user`;
    return super
      .$fetch(urlWithParams)
      .then((data) => ({
        key: this._key,
        data: data,
      }))
      .catch((error) => {
        console.error("Error fetching transactions:", error);
        return Promise.reject(error);
      });
  }
}
