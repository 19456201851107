/**
 * projects.service.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2024
 * All rights reserved
 */

import { APIService } from '../api/index';

let instance;

export class ProjectsService extends APIService {
    constructor() {
        const collection = 'projects';
        super(collection, 'projects');
    }
    static create() {
        if (!instance) {
            instance = new ProjectsService();
        }
        return instance;
    }
}
