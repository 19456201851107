import React from "react";
import { Card, Icon } from "semantic-ui-react";

const ActionCard = ({ iconName, iconColor, description, onClick }) => {
  return (
    <Card link onClick={onClick} className="centered-card bigger-card">
      <Card.Content className="centered-content">
        <div className="icon-container">
          <Icon name={iconName} size="huge" style={{ color: iconColor }} />
        </div>
        <Card.Description>
          <div className="card-description">{description}</div>
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

export default ActionCard;
