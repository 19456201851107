// src/components/FileUploader.js
import React, { useState, useEffect } from 'react';
import { Upload, Button, message, Progress, Popconfirm, Spin } from 'antd';
import { Icon, Header, Segment, List } from 'semantic-ui-react';
import {
	CloudUploadOutlined,
	DeleteOutlined,
	DeleteTwoTone,
} from '@ant-design/icons';
import { storage } from '../../config/firebase';
import {
	ref,
	uploadBytesResumable,
	getDownloadURL,
	deleteObject,
} from 'firebase/storage';

import {
	FileService,
	SessionService,
	ProjectsService,
} from '../../services/index';
import axios from 'axios';
import { AV_FILE_VIEWER } from '../shared/AV_FILE_VIEWER';
import { AV_NOTIFY } from '../shared/AV_NOTIFY';

const fileService = FileService.create();
const projectsService = ProjectsService.create();

const FileUploader = ({ transaction }) => {
	const { _id: transactionId = '65d3b6e484df83132e13a071', project } =
		transaction;
	const [fileList, setFileList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [progress, setProgress] = useState(0);
	const [currentProject, setCurrentProject] = useState(undefined);
	const [viewDocument, setViewDocument] = useState(false);
	const [currentFile, setCurrentFile] = useState(undefined);
	const [bucketName, setBucketName] = useState('files');

	useEffect(() => {
		// Fetch existing files from your API if needed
		fetchFiles();
		fetchProject();
	}, [transaction]);

	const fetchFiles = async () => {
		setLoading(true);
		fileService.fetch(`files?transaction=${transactionId}`).subscribe(
			(data) => {
				setLoading(false);
				setFileList(data);
			},
			(error) => {
				console.log('file fetch error', error);
			}
		);
	};
	const fetchProject = async () => {
		projectsService.getOne(`${project}`).subscribe(
			(data) => {
				console.log('Project: ', project);
				setLoading(false);
				setCurrentProject(data);
				setBucketName(data.name);
			},
			(error) => {
				console.log('file fetch error', error);
			}
		);
	};

	const handleUpload = ({ file }) => {
		const storageRef = ref(storage, `${bucketName}/${file.name}`);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			'state_changed',
			(snapshot) => {
				const progress =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				setProgress(progress);
				console.log('Upload is ' + progress + '% done');
			},
			(error) => {
				console.error('Upload failed:', error);
				message.error(`Upload failed: ${error.message}`);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					console.log('File available at', downloadURL);
					createDocumentOnAPI(downloadURL, file);
				});
			}
		);
	};

	const createDocumentOnAPI = async (url, file) => {
		if (!loading) {
			setLoading(true);
		}
		fileService
			.create({
				url,
				name: file.name,
				transaction: transactionId,
				size: file.size,
				type: file.type,
			})
			.subscribe(
				(data) => {
					setFileList([...fileList, data]);
					setLoading(false);
				},
				(err) => {
					console.error('Error while saving the file URL to database: ', err);
					message.error(
						'An error occurred when trying to save the file information in the database'
					);
				}
			);
	};

	const deleteFileOnAPI = async (file) => {
		fileService.delete(file._id).subscribe(
			(data) => {
				AV_NOTIFY({
					title: 'Deleted',
					content: `Document Deleted ${file.name}`,
					type: 'success',
					duration: 10,
				});
				fetchFiles();
			},
			(err) => console.error('Delete Error: ', err), // eslint-disable-line no-console
			() => setLoading(false)
		);
	};

	const deleteFile = async (file) => {
		try {
			setLoading(true);
			// Delete from Firebase Storage
			const fileRef = ref(storage, `${bucketName}/${file.name}`);
			await deleteObject(fileRef);

			await deleteFileOnAPI(file);
		} catch (error) {
			console.error('Error deleting file:', error);
		}
	};

	/**
	 * @description render live upload progress of a file to firebase
	 * @param {Number} progress file upload progress
	 */
	const renderProgress = (progress) => {
		const uploadStopped = progress === 0;
		const uploadFinished = progress === 100;
		return uploadStopped || uploadFinished ? null : (
			<Header as="h4" color="blue">
				<Header.Content>Uploading {currentFile?.name}</Header.Content>
				<Progress
					strokeColor={{ from: 'red', to: 'green' }}
					percent={progress.toFixed(1)}
					status="active"
				/>
			</Header>
		);
	};

	/**
	 * @description renders the pdf documents list
	 * @param {Object} file pdf file reference
	 * @param {*} index loop index
	 */
	const renderFiles = (file, index) => {
		return (
			<List.Item key={index}>
				<List.Content floated="right">
					<Popconfirm
						placement="top"
						title="Confirm Delete"
						okText="Yes"
						cancelText="No"
						onConfirm={() => deleteFile(file)}
					>
						<Button
							shape="circle"
							icon={<DeleteTwoTone key="delete" twoToneColor="red" />}
						/>
					</Popconfirm>
				</List.Content>
				<Icon
					name={'file pdf outline'}
					size="big"
					onClick={() => {
						setCurrentFile(file);
						setViewDocument(true);
					}}
					color="red"
				/>
				<List.Content> {file.name}</List.Content>
			</List.Item>
		);
	};

	/**
	 * @description upload dragger properties
	 */
	const draggerProps = {
		multiple: true,
		accept: 'image/*,.pdf, video/*, audio/*',
		name: 'file',
		itemRender() {
			return null;
		},
		customRequest: ({ file }) => {
			handleUpload({ file });
		},
	};

	return (
		<div>
			<Segment basic className="buildingPlansUploadSegment">
				<br />
				<Upload.Dragger {...draggerProps}>
					<p className="ant-upload-drag-icon">
						<CloudUploadOutlined />
					</p>
					<p className="ant-upload-hint">
						Click or drag file to this area to upload
					</p>
				</Upload.Dragger>
				<br />
				{renderProgress(progress)}
				<br />

				<Spin spinning={loading}>
					{fileList.length !== 0 && (
						<List divided verticalAlign="middle" animated size="small">
							{fileList.map(renderFiles)}
						</List>
					)}
					{viewDocument && (
						<AV_FILE_VIEWER
							previewVisible={viewDocument}
							closeModal={() => setViewDocument(false)}
							pdf={currentFile}
						/>
					)}
				</Spin>
			</Segment>

			<List
				itemLayout="horizontal"
				dataSource={fileList}
				renderItem={(item) => (
					<List.Item
						actions={[
							<Button
								icon={<DeleteOutlined />}
								onClick={() => deleteFile(item)}
							/>,
						]}
					>
						<List.Item.Meta title={item.name} description={item.url} />
					</List.Item>
				)}
			/>
		</div>
	);
};

export default FileUploader;
