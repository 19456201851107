import React, { useEffect, useState } from "react";
import { AV_MODAL } from "../../shared/AV_MODAL";
import { AV_FILE_VIEWER } from "../../shared/AV_FILE_VIEWER";
import {
  Button,
  Divider,
  Grid,
  Header,
  List,
  Tab,
  Form,
  TextArea,
  Icon,
} from "semantic-ui-react";

const EditTransactionModal = ({
  isOpen,
  onClose,
  transactionData,
  files,
  onDeleteFile,
  onUploadFile,
}) => {
  // Set initial state for editedTransaction with transactionData
  const [editedTransaction, setEditedTransaction] = useState(
    transactionData || {
      project: "",
      amount: "",
      type: "",
      notes: "",
    }
  );
  const [viewerOpen, setViewerOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState({ url: "", name: "" });

  // Update editedTransaction state when transactionData changes
  useEffect(() => {
    setEditedTransaction(transactionData);
  }, [transactionData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedTransaction({ ...editedTransaction, [name]: value });
  };

  const handleFileClick = (file) => {
    setSelectedFile(file);
    setViewerOpen(true);
  };

  const handleDeleteFile = (file) => {
    // Call the onDeleteFile callback with the file to be deleted
    onDeleteFile(file);
  };

  const handleUploadFile = (e) => {
    // Access the uploaded file(s) from the event
    const uploadedFiles = Array.from(e.target.files);
    // Call the onUploadFile callback with the uploaded file(s)
    onUploadFile(uploadedFiles);
  };

  const closeViewer = () => {
    setViewerOpen(false);
  };

  const handleFormSubmit = () => {
    // Handle form submission, e.g., call an API to update the transaction
    console.log("Edited Transaction:", editedTransaction);
    // Close modal after submitting
    onClose();
  };

  // Filter files based on type
//   const documents = files.filter((file) => file.type.includes("pdf"));
//   const images = files.filter(
//     (file) =>
//       ["image/jpeg", "image/png", "image/webp"].indexOf(file.type) !== -1
//   );

  // Filter files based on type
const documents = files ? files.filter((file) => file.type.includes("pdf")) : [];
const images = files ? files.filter(
  (file) =>
    ["image/jpeg", "image/png", "image/webp"].indexOf(file.type) !== -1
) : [];

  return (
    <>
      <AV_MODAL
        title="Edit Transaction"
        visible={isOpen}
        handleCancel={onClose}
        size="large"
        scrolling={true}
      >
        <Form>
          {/* Input fields for editing transaction details */}

          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Form.Field>
                  <label>Project Name</label>
                  <input
                    name="project"
                    value={editedTransaction?.project || ""}
                    onChange={handleInputChange}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <label>Amount (N$)</label>
                  <input
                    name="amount"
                    type="number"
                    value={editedTransaction?.amount || ""}
                    onChange={handleInputChange}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2}>
              <Grid.Column>
                <Form.Field>
                  <label>Transaction Type</label>
                  <input
                    name="type"
                    value={editedTransaction?.type || ""}
                    onChange={handleInputChange}
                  />
                </Form.Field>
              </Grid.Column>

              <Grid.Column>
                <Form.Field>
                  <label>Notes</label>
                  <TextArea
                    name="notes"
                    value={editedTransaction?.notes || ""}
                    onChange={handleInputChange}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field>
                  <label>Media</label>
                  {/* Render tabs for media and documents */}
                  <Tab
                    menu={{ secondary: true, pointing: true }}
                    panes={[
                      {
                        menuItem: "Documents",
                        render: () => (
                          <Tab.Pane>
                            {/* Render documents */}
                            {documents.length > 0 ? (
                              <List divided>
                                {documents.map((doc, index) => (
                                  <List.Item
                                    key={index}
                                    onClick={() => handleFileClick(doc)}
                                  >
                                    <List.Icon name="file pdf outline" />
                                    <List.Content>
                                      <List.Header>{doc.name}</List.Header>
                                    </List.Content>
                                    <List.Icon
                                      name="trash"
                                      color="red"
                                      onClick={() => handleDeleteFile(doc)}
                                    />
                                  </List.Item>
                                ))}
                              </List>
                            ) : (
                              <Header as="h5" textAlign="center">
                                No documents available for this transaction.
                              </Header>
                            )}
                            <input
                              type="file"
                              accept=".pdf"
                              onChange={handleUploadFile}
                              style={{ marginTop: "10px" }}
                            />
                          </Tab.Pane>
                        ),
                      },
                      {
                        menuItem: "Media",
                        render: () => (
                          <Tab.Pane>
                            {/* Render images */}
                            {images.length > 0 ? (
                              <List divided relaxed style={{ width: "100%" }}>
                                {images.map((image, index) => (
                                  <List.Item
                                    key={index}
                                    onClick={() => handleFileClick(image)}
                                  >
                                    <List.Icon name="image outline" />
                                    <List.Content>
                                      <List.Header>{image.name}</List.Header>
                                    </List.Content>
                                    <List.Icon
                                      name="trash"
                                      color="red"
                                      onClick={() => handleDeleteFile(image)}
                                    />
                                  </List.Item>
                                ))}
                              </List>
                            ) : (
                              <Header as="h5" textAlign="center">
                                No images available for this transaction.
                              </Header>
                            )}
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleUploadFile}
                            />
                          </Tab.Pane>
                        ),
                      },
                    ]}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>

            {/* Display additional transaction details */}
          </Grid>
        </Form>
        <Divider />
        {/* Buttons for saving changes and closing modal */}
        <Button primary onClick={handleFormSubmit}>
          Save Changes
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </AV_MODAL>
      <AV_FILE_VIEWER
        pdf={selectedFile}
        previewVisible={viewerOpen}
        closeModal={closeViewer}
      />
    </>
  );
};

export default EditTransactionModal;
