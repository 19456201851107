import React, { useState } from "react";
import { Grid, Card } from "semantic-ui-react";
import { useAuth } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import "./styles/Home.css";
import CreateProjectModal from "../components/common/projects/CreateProjectModal";
import ActionCard from "../components/common/ActionCard";
import { AV_MODAL } from "../components/shared/AV_MODAL";
import CreateEditTransactionForm from "../components/common/transactions/CreateTransactionForm";

export const Home = () => {
  const navigate = useNavigate();
  const { userData } = useAuth();
  const userId = userData ? userData._id : null;
  const [activeModal, setActiveModal] = useState(null);
  const [isCreateTransactionModalOpen, setIsCreateTransactionModalOpen] =
    useState(false);

  const openModal = (modalType) => setActiveModal(modalType);
  const closeModal = () => setActiveModal(null);

  const modalComponents = {
    createProject: CreateProjectModal,
  };

  const handleCardClick = (modalType) => {
    openModal(modalType);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const renderModal = () => {
    if (!activeModal) return null;

    const ModalComponent = modalComponents[activeModal];
    return (
      <ModalComponent
        open={!!activeModal}
        onClose={closeModal}
        isNewProject={true}
        user={userId}
      />
    );
  };

  return (
    <>
      {/* <HeaderTop /> */}
      <div className="home-container">
        <Card.Group style={{ justifyContent: "center" }}>
          <ActionCard
            iconName="edit"
            iconColor="var(--primary-color)"
            description="Create Project"
            onClick={() => handleCardClick("createProject")}
          />
          <ActionCard
            iconName="exchange"
            iconColor="var(--accent-color)"
            description="Create Transaction"
            onClick={() => setIsCreateTransactionModalOpen(true)}
          />
          <ActionCard
            iconName="dashboard"
            iconColor="var(--grey-color)"
            description="Go To Dashboard"
			onClick={()=>handleNavigation('/projects')}
          />
        </Card.Group>
        {renderModal()}
        <AV_MODAL
          scrolling={false}
          title={"Create Transaction"}
          visible={isCreateTransactionModalOpen}
          handleCancel={() => {
            setIsCreateTransactionModalOpen(false);
          }}
          size={"large"}
          children={
            <CreateEditTransactionForm
              mode={"create"}
              project={undefined}
              handleCancel={() => {
                setIsCreateTransactionModalOpen(false);
              }}
            />
          }
        />
      </div>
    </>
  );
};
