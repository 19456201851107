/**
 * AV_INPUT.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2024
 * All rights reserved
 */

import React from 'react';
import { Form, TextArea } from 'semantic-ui-react';
import { getIn } from 'formik';
import { AV_ERRORLABEL } from './AV_ERRORLABEL';

export function AV_TEXTAREA(props) {
	const {
		errors,
		touched,
		name,
		handleChange,
		handleBlur,
		label,
		required = false,
		disabled = false,
		value = '',
		rows = 5,
		width = 16,
		styles = {},
		placeholder,
	} = props;

	const isErrored =
		getIn(errors, name) && getIn(touched, name) !== undefined ? true : false;

	const style = {
		...styles,
	};

	Object.byString = function (o, s) {
		s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
		s = s.replace(/^\./, ''); // strip a leading dot
		var a = s.split('.');
		for (var i = 0, n = a.length; i < n; ++i) {
			var k = a[i];
			if (k in o) {
				o = o[k];
			} else {
				return;
			}
		}
		return o;
	};

	return (
		<Form.Field
			style={style}
			width={width}
			required={required}
			error={isErrored}
			onChange={handleChange}
			onBlur={handleBlur}
		>
			<label>{label}</label>

			<TextArea
				placeholder={placeholder}
				value={value}
				onChange={handleChange}
				onBlur={handleBlur}
				name={name}
				rows={rows}
				autosize={{ minRows: 4, maxRows: 10 }}
			/>
			{isErrored && <AV_ERRORLABEL children={Object.byString(errors, name)} />}
		</Form.Field>
	);
}
