import React, { useState, useEffect } from "react";
import axios from "axios";
import { AV_MODAL } from "../../shared/AV_MODAL";
import { Button, Popconfirm } from "antd";
import { DeleteTwoTone, EditTwoTone, EyeTwoTone } from "@ant-design/icons";
import CreateProjectForm from "./CreateProjectForm";
import { VaultTable } from "../../common/VaultTable";
import { ENV } from "../../../config/env.config";
import { AV_NOTIFY } from "../../shared/AV_NOTIFY";
import { TransactionsService, ProjectsService } from "../../../services";
import EditTransactionModal from "../transactions/EditTransactionModal";
import ViewTransactionModal from "../transactions/ViewTransactionModal";
import { Divider } from "semantic-ui-react";

const baseUrl = ENV.baseUrl;
const transactionsService = TransactionsService.create();
const projectsService = ProjectsService.create();

const ProjectDetailsModal = ({ visible, onClose, projectId, mode }) => {
  const [projectDetails, setProjectDetails] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [project, setProject] = useState(null);
  const [editData, setEditData] = useState({});
  const [transactionToDelete, setTransactionToDelete] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const fetchProjects = async () => {
    try {
      const projects = await projectsService.$get();
      setProject(projects.data.find((proj) => proj._id === projectId));
    } catch (error) {
      console.error("Error fetching projects:", error);
      setProject(null);
    }
  };

  const fetchTransactions = async () => {
    try {
      const transactions = await transactionsService.$get();
      // Filter transactions based on projectId
      const projectTransactions = transactions.data.filter(
        (transaction) => transaction.project._id === projectId
      );
      setTransactions(projectTransactions);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setTransactions([]);
    }
  };

  useEffect(() => {
    const fetchProjectDetails = async () => {
      if (!projectId || !visible) {
        setProjectDetails({});
        setTransactions([]);
        setEditData({});
        return;
      }
      try {
        await fetchTransactions();
        await fetchProjects();
      } catch (error) {
        console.error("Error fetching project details:", error);
        setTransactions([]);
        setProject([]);
      }
    };

    fetchProjectDetails();
  }, [visible, projectId]);

  const handleEditChange = (e, { name, value }) => {
    setEditData({ ...editData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      await axios.put(`${baseUrl}/projects/${projectId}`, editData);
      onClose();
      AV_NOTIFY({
        title: `SUCCESS`,
        content: "Project Updated",
        type: "success",
        duration: 3,
      });
    } catch (error) {
      console.error("Error updating project details:", error);
    }
  };

  const handleConfirmDelete = async (transaction) => {
    try {
      // Perform a patch request to update the transaction's 'deleted' field to true
      await transactionsService.deleteTransaction(transaction._id);
      // Show success message
      AV_NOTIFY({
        title: `SUCCESS`,
        content: "Transaction Deleted",
        type: "success",
        duration: 3,
      });
      // Refetch transactions after successfully updating the 'deleted' field
      fetchTransactions();
    } catch (error) {
      console.error("Error deleting transaction:", error);
      // Handle error gracefully, display an error message to the user if needed
      AV_NOTIFY({
        title: `ERROR`,
        content:
          "An error occurred while deleting the transaction. Please try again.",
        type: "error",
        style: {
          background: "#0c3a51",
          zIndex: 4000,
          color: "white",
        },
        duration: 3,
      });
    }
  };

  const openEditTransactionModal = (transaction, mode) => {
    setSelectedTransaction(transaction);
    setIsEditModalOpen(true);
  };

  const openViewTransactionModal = (transaction, mode) => {
    setSelectedTransaction(transaction);
    setIsViewModalOpen(true);
  };

  const transactionColumns = [
    { title: "Amount", dataIndex: "amount", key: "amount" },
    {
      title: "Transaction Type",
      dataIndex: "type",
      key: "type",
      render: (type) => type.name,
    },
    { title: "Notes", dataIndex: "notes", key: "notes" },
  ];

  const actions = (transaction) => {
    return (
      <div className="ui three buttons">
        <Button
          style={styles.tableActionButtons}
          shape="circle"
          onClick={() => openEditTransactionModal(transaction, "edit")}
          icon={<EditTwoTone key="Edit" twoToneColor={"blue"} />}
        />
        <Button
          style={styles.tableActionButtons}
          shape="circle"
          onClick={() => openViewTransactionModal(transaction, "view")}
          icon={<EyeTwoTone key="View" twoToneColor={"green"} />}
        />
        <Popconfirm
          placement="top"
          title="Confirm Delete"
          okText="Yes"
          cancelText="No"
          onConfirm={() => handleConfirmDelete(transaction)}
        >
          <Button
            style={styles.tableActionButtons}
            shape="circle"
            icon={<DeleteTwoTone key="delete" twoToneColor="red" />}
          />
        </Popconfirm>
      </div>
    );
  };

  return (
    <>
      <AV_MODAL
        visible={visible}
        handleCancel={onClose}
        title={mode === "edit" ? "Edit Project" : project ? project.name : ""}
        size={mode === "edit" ? "tiny" : "large"}
      >
        {mode === "edit" ? (
          <CreateProjectForm
            isNewProject={false}
            onClose={onClose}
            initialData={editData}
          />
        ) : (
          <>
            <h4>Status: {project ? project.status : ""}</h4>
            <Divider />
            <h4>Transactions</h4>
            <Divider />
            <VaultTable
              rowKey="id"
              tableActions={actions}
              data={transactions}
              columns={transactionColumns}
            />
          </>
        )}
      </AV_MODAL>

      <EditTransactionModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        transactionData={selectedTransaction}
      />

      <ViewTransactionModal
        isOpen={isViewModalOpen}
        onClose={() => setIsViewModalOpen(false)}
        transactionData={selectedTransaction}
      />
    </>
  );
};

export default ProjectDetailsModal;

const styles = {
  tableActionButtons: {
    marginLeft: "2%",
    marginRight: "2%",
  },
};
