import React from "react";
import Logo from "../../assets/Logo.png";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

import "./Header.css";
import { AV_DROPDOWN } from "../shared/AV_DROPDOWN";
import { Dropdown, Image } from "semantic-ui-react";

export const HeaderTop = () => {
  // Retrieve userId from AuthContext
  const { userData, logout } = useAuth();

  const userEmail = userData ? userData.email : null;
  // Extract the first letter of the user's email for the avatar
  const avatarInitial = userEmail ? userEmail.charAt(0).toUpperCase() : "";
  const trigger = (
    <span className="user-functions">
      <div className="user-email">{userEmail}</div>
      <div className="avatar">{avatarInitial}</div>
    </span>
  );
  const options = [
    { key: "settings", text: "Settings", icon: "settings", disabled: true },
    {
      key: "sign-out",
      text: "Sign Out",
      icon: "sign out",
      onClick: () => logout(),
    },
  ];

  return (
    <div className="header-container">
      <div className="logo-container">
        <Link to="/">
          <img src={Logo} alt="Logo" className="logo-image" />
        </Link>
      </div>
      {userEmail && (
        <>
          <div className="user-info-container">
            <Dropdown
              trigger={trigger}
              options={options}
              pointing="top right"
              icon={null}
            />
          </div>
        </>
      )}
    </div>
  );
};
