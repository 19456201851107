import React, { useState, useEffect } from "react";
import {
  Grid,
  Menu,
  Segment,
  Icon,
  Header,
  Breadcrumb,
} from "semantic-ui-react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import Toolbar from "../components/common/Toolbar";
import { useAuth } from "../context/AuthContext";
import Swal from "sweetalert2";
import { VaultTable } from "../components/common/VaultTable";
import { AV_MODAL } from "../components/shared/AV_MODAL";
import CreateEditTransactionForm from "../components/common/transactions/CreateTransactionForm";
import { ENV } from "../config/env.config";
import "./styles/Projects.css";
import { dateTimeFormat } from "../helpers/date.util";
import { APIService } from "../services";

const baseUrl = ENV.baseUrl;

export const Users = () => {
  // Retrieve userId from AuthContext
  const { userData } = useAuth();
  const currentUserId = userData ? userData._id : null;

  const [users, setUsers] = useState([]); // Change state name to transactions
  const [isCreateTransactionModalOpen, setIsCreateTransactionModalOpen] =
    useState(false);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [searchResults, setSearchResults] = useState([]);

  // APIService instance
  const usersService = new APIService(`${baseUrl}/users`, "users");

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const fetchedUsers = await usersService.$fetch();
      setUsers(fetchedUsers);
      setSearchResults(fetchedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  },[]);

  const handleConfirmDelete = async (user) => {
    setUserToDelete(user._id);

    if (userToDelete) {
      try {
        await usersService.$delete(user._id);
        fetchUsers(); // Refetch the users after deletion
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "success",
          title: "User deleted",
        });
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  const handleSearch = (searchTerm) => {
    const filteredResults = users.filter((user) =>
      (user.firstName + " " + user.lastName)
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredResults);
  };

  return (
    <>
      <div className="breadcrumb-container">
        <Breadcrumb>
          <Breadcrumb.Section as={Link} to="/">
            <Icon name="home" />
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section active>Users</Breadcrumb.Section>
        </Breadcrumb>
      </div>
      <Grid style={{ padding: "30px", fontFamily: "Jost, sans-serif" }}>
        <Grid.Column width={2}>
          <Menu fluid vertical tabular>
            <Menu.Item
              name="projects"
              as={Link}
              to="/projects"
              active={location.pathname === "/projects"}
            >
              <Icon name="clipboard list" />
              <p style={{ fontFamily: "Jost, sans-serif", fontWeight: "400" }}>
                Projects
              </p>
            </Menu.Item>
            <Menu.Item
              name="transactions"
              as={Link}
              to="/transactions"
              active={location.pathname === "/transactions"}
            >
              <Icon name="exchange" />
              <p style={{ fontFamily: "Jost, sans-serif", fontWeight: "400" }}>
                Transactions
              </p>
            </Menu.Item>
            <Menu.Item
              name="users"
              as={Link}
              to="/users"
              active={location.pathname === "/users"}
            >
              <Icon name="users" />
              <p style={{ fontFamily: "Jost, sans-serif", fontWeight: "400" }}>
                Users
              </p>
            </Menu.Item>
          </Menu>
        </Grid.Column>
        <Grid.Column stretched width={14}>
          <Segment>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Header
                as="h2"
                style={{ fontFamily: "Jost, sans-serif", fontWeight: "500" }}
              >
                Autovault Users
              </Header>
              <Toolbar
                onRefresh={fetchUsers}
                onSearch={handleSearch}
                isTransactions={true} // Pass true to indicate it's the Transactions component
                modalComponent={
                  <AV_MODAL
                    scrolling={false}
                    title={"Create User"}
                    visible={isCreateTransactionModalOpen}
                    handleCancel={() => {
                      setIsCreateTransactionModalOpen(false);
                    }}
                    size={"large"}
                    children={
                      <CreateEditTransactionForm
                        mode={"create"}
                        project={undefined}
                        handleCancel={() => {
                          setIsCreateTransactionModalOpen(false);
                        }}
                      />
                    }
                  />
                }
              />
            </div>
            <VaultTable
              data={searchResults}
              title="Users"
              columns={[
                {
                  title: "First Name",
                  key: "firstName",
                  dataIndex: "firstName",
                  searchable: true,
                },
                {
                  title: "Last Name",
                  key: "lastName",
                  dataIndex: "lastName",
                  searchable: true,
                },
                {
                  title: "Email",
                  key: "email",
                  dataIndex: "email",
                  searchable: true,
                },
                {
                  title: "Number Of Projects",
                  key: "projectsCount",
                  dataIndex: "projectsCount",
                  render: (text, record) => record.projectsCount || 0,
                },
                {
                  title: "Created",
                  key: "created",
                  dataIndex: "createdAt",
                  render: (createdAt) => dateTimeFormat(new Date(createdAt)),
                },
              ]}
              rowKey="_id"
              scroll={{ x: 100, y: 1000 }}
              paginationSettings={{
                position: "bottomRight",
                current: currentPage,
                pageSize: itemsPerPage,
                total: searchResults.length,
              }}
              loading={loading}
              refetch={fetchUsers}
              confirmDelete={handleConfirmDelete}
              refreshing={loading}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
};
