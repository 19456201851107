/**
 * array.util.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright (c) 2024
 * All rights reserved
 */

export function uniqueFilter(value, index, self) {
    return self.indexOf(value) === index;
}

/**
 * @description sorts strings in alphabetic order by key "name"
 * @param {Array<Object>} collection array of objects to be sorted
 * @returns {Array<Object>} returns sorted array of objects
 */
export function sortByname(collection) {
    return collection.sort(function (a, b) {
        return a.name.localeCompare(b.name);
    });
}

/**
 * @description converts Map into array
 * @param {Map} collection map collection to be converted
 * @returns {Array} array type of the Map given
 */
export function toArray(collection) {
    return Array.isArray(collection) ? collection : Array.from(collection.values());
}

/**
 * @description returns true if all collections are not empty
 * @param {Array} items
 */
export function notEmpty(items) {
    items = Array.isArray(items) ? items : toArray(items);
    return items.every((item) => item.length !== 0);
}

/**
 * @description returns true if collection is empty
 * @param {Array} items
 */
export function isEmpty(collection) {
    return collection.length === 0;
}

/**
 *
 * @param {Array} array array collection
 * @param {*} value value to be checked
 * @returns
 */
export function getOccurrence(array, value) {
    return array.filter((v) => v === value).length;
}

/**
 *
 * @description shuffles items in an array
 * @param {Array} unshuffledArray array with ordered items
 * @returns {Array} shuffled array
 */
export function shuffleArray(unshuffledArray) {
    const shuffled = unshuffledArray
        .map((a) => ({ sort: Math.random(), value: a }))
        .sort((a, b) => a.sort - b.sort)
        .map((a) => a.value);

    return shuffled;
}

/**
 *
 * @description groups objects in an array based on given attribute
 * @param {Array} collection array ungrouped items
 * @returns {String} attribute to be grouped by
 */
export function groupByAttribute(collection, attribute) {
    const result = collection.reduce(function (r, a) {
        r[a[attribute]] = r[a[attribute]] || [];
        r[a[attribute]].push(a);
        return r;
    }, Object.create(null));

    return result;
}
