/**
 * files.service.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2024
 * All rights reserved
 */

import { APIService } from "../api/index";

let instance;

export class FileService extends APIService {
  constructor() {
    const collection = "files";
    super(collection, "files");
  }

  static create() {
    if (!instance) {
      instance = new FileService();
    }
    return instance;
  }

  // Method to fetch all files for a specific transaction ID
  async get(transactionId) {
    return await this.get(`?transaction=${transactionId}`);
  }
}
