
// src/firebase-config.js
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyDJDOeHMiSmtl31dSxQjE8PCJfAphwTG08",
  authDomain: "autovault-2cafd.firebaseapp.com",
  projectId: "autovault-2cafd",
  storageBucket: "autovault-2cafd.appspot.com",
  messagingSenderId: "893272045399",
  appId: "1:893272045399:web:3b83eff902e3943ee77012",
  measurementId: "G-Q4F2HQXTJR"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };