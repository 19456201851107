import React, { useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import CreateProjectForm from "./CreateProjectForm";
import { AV_MODAL } from "../../shared/AV_MODAL";

const CreateProjectModal = ({ open, onClose, isNewProject }) => {
  const { userData } = useAuth();
  const userId = userData ? userData._id : null;

  // State to hold form data
  const [project, setProject] = useState({
    name: "",
    status: "",
    user: userId,
  });

  return (
    <AV_MODAL
      visible={open}
      handleCancel={onClose}
      title={isNewProject ? "Create Project" : "Update Project"}
      headerVisible={true}
      size="tiny"
    >
      <CreateProjectForm
        isNewProject={isNewProject}
        onClose={onClose}
        initialData={project}
      />
    </AV_MODAL>
  );
};

export default CreateProjectModal;
