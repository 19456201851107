/**
 * storage.service.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright (c) 2024
 * All rights reserved
 */
import { Logger } from "../helpers/index";
import CryptoJS from "crypto-js";
const { REACT_APP_AUTO_VAULT_JWT_SECRET, REACT_APP_ENCRYPT_DATA } = process.env;

/**
 * @description stores data to the device local storage
 * @param {string} key key identifier of the data
 * @param {any} value data to be stored
 */

export const _store = async (key, value) => {
  try {
    const shouldEncrypt = REACT_APP_ENCRYPT_DATA === true;
    if (value && key) {
      const encrypted = shouldEncrypt
        ? CryptoJS.AES.encrypt(
            JSON.stringify(value),
            REACT_APP_AUTO_VAULT_JWT_SECRET
          ).toString()
        : JSON.stringify(value);
      return localStorage.setItem(key, encrypted);
    }
  } catch (error) {
    Logger.log("Error", error);
  }
};

/**
 *
 * @param {string} key key of the value in AsyncStorage to be retrieved
 * @returns {*} Value matching the key given
 */
export const _retrieve = async (key) => {
  try {
    const shouldEncrypt = REACT_APP_ENCRYPT_DATA === true;
    const value = localStorage.getItem(key);

    if (value !== null) {
      const decrypted = shouldEncrypt ? CryptoJS.AES.decrypt(
        value,
        REACT_APP_AUTO_VAULT_JWT_SECRET
      ).toString(CryptoJS.enc.Utf8) : value;
      return JSON.parse(decrypted);
    } else {
      throw new Error("Not Found");
    }
  } catch (error) {
    Logger.log(`${key} not found!`);
    return undefined;
  }
};

/**
 * @description Removes an item matching key given
 * @param {string} key key identifier of item to be removed
 * @returns {Promise<Object>}
 */
export const _remove = async (key) => {
  try {
    return localStorage.removeItem(key);
  } catch (error) {
    Logger.log("Error", error);
  }
};

/**
 * @description Erases all AsyncStorage for all clients, libraries.
 * @description you probably don't want to call this; use removeItem or multiRemove to clear only your app's keys
 */
export const _flush = async () => {
  try {
    localStorage.clear();
  } catch (error) {
    Logger.log("Error", error);
    throw error;
  }
};
