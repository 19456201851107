/**
 * AV_FILE_VIEWER.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2024
 * All rights reserved
 */

import React from 'react';
import { AV_MODAL } from './AV_MODAL';

export function AV_FILE_VIEWER(props) {
	const {
		pdf,
		previewVisible,
		closeModal,
		blurContent = false,
	} = props;
	const { url = '', name = 'Document Name' } = pdf;
	return (
		<AV_MODAL
			scrolling={false}
			title={pdf.name}
			visible={previewVisible}
			handleCancel={closeModal}
			size={'large'}
			blurContent={blurContent}
			children={
				<IframeViewerComponent
					url={url}
					name={name}
				/>
			}
		/>
	);
}

const IframeViewerComponent = ({ url, name }) => {
	return (
		<iframe
			id="pdfPreview"
			src={url}
			title={name}
			height="780"
			width="100%"
			allow="fullscreen"
			align="centre"
			loading="lazy"
		>
			<p>Your browser does not support this view.</p>
		</iframe>
	);
};
