/**
 * AV_DROPDOWN.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2024
 * All rights reserved
 */

import React from 'react';
import { Form, Dropdown } from 'semantic-ui-react';
import { getIn } from 'formik';
import { AV_ERRORLABEL } from './AV_ERRORLABEL';

export function AV_DROPDOWN(props) {
    const {
        errors,
        touched,
        name,
        onBlur,
        onChange,
        label,
        required = false,
        disabled = false,
        defaultValue = '',
        width = 16,
        options = [],
        styles = {},
        id = '',
        onSearchChange,
        search = false,
        multiple = false,
        noResultsMessage = 'Selection Empty',
        placeholder = '',
        className = '',
        labelStyles = {},
        loading = false,
        rest,
    } = props;

    const isErrored = getIn(errors, name) && getIn(touched, name) !== undefined ? true : false;
    const style = {
        ...styles,
    };
    const labelStyle = {
        ...labelStyles,
    };

    Object.byString = function (o, s) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, ''); // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    };

    return (
        <Form.Field width={width} required={required} error={isErrored}>
            <label style={labelStyle}>{label}</label>
            <Dropdown
                className={className}
                defaultValue={defaultValue}
                floating
                name={name}
                fluid
                id={id}
                search={search}
                options={options}
                selection
                multiple={multiple}
                noResultsMessage={noResultsMessage}
                placeholder={placeholder}
                onBlur={onBlur}
                onChange={onChange}
                onSearchChange={onSearchChange}
                disabled={disabled}
                loading={loading}
                {...rest}
            />
            {isErrored && <AV_ERRORLABEL children={Object.byString(errors, name)} />}
        </Form.Field>
    );
}
