/**
 * OxTable.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2023
 * All rights reserved
 */

import React, { useState, useEffect, useRef } from "react";
import { Table, Popconfirm, Button, Input, Space } from "antd";
import {
  DeleteTwoTone,
  EditTwoTone,
  EyeTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import { Row, Col } from "react-bootstrap";
import Highlighter from "react-highlight-words";

export function VaultTable(props) {
  const {
    data,
    queryData,
    refetch,
    columns,
    title,
    scroll = { x: 100, y: 1000 },
    paginationSettings = {
      position: "bottomRight",
      current: 1,
      pageSize: 5,
      total: 0,
    },
    loading,
    updateState,
    confirmDelete,
    refreshing,
    tableActions,
    settings,
    ...rest
  } = props;

  const [tableData, setTableData] = React.useState([]);
  const [pagination, setPagination] = React.useState(paginationSettings);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const searchInput = useRef(null);

  useEffect(() => {
    if (queryData) {
      setTableData(queryData.data);
      setPagination((prev) => ({
        ...prev,
        total: queryData.total,
      }));
    }
  }, [queryData]);

  const handleTableChange = (newPagination) => {
    setPagination(newPagination);
    refetch({
      limit: newPagination.pageSize,
      skip: (newPagination.current - 1) * newPagination.pageSize,
    });
  };

  const navigate = (path) => {
    alert("ss");
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={`${selectedKeys[0] || ""}`}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const handlePagination = (page) => {
    navigate(`${parseInt(page.current)}`);
  };

  const unfilteredData = data;

  const tableSettings = {
    pagination: pagination,
    bordered: false,
    loading: refreshing,
    size: "middle",
    rowKey: "id",
    fixed: true,
    responsive: "md",
    locale: { emptyText: "No Data Available" },
    ...settings,
  };

  const transformedColumns = columns.map((column) => {
    let transformedColumn = { ...column };

    if (column.searchable) {
      transformedColumn = {
        ...transformedColumn,
        ...getColumnSearchProps(column.key),
      };
    }

    if (column.sortable) {
      transformedColumn.sorter = (a, b) => {
        const aindex = a[column.dataIndex] ? a[column.dataIndex] : "";
        const bindex = b[column.dataIndex] ? b[column.dataIndex] : "";
        return aindex.length - bindex.length;
      };
      transformedColumn.sortDirections = ["descend", "ascend"];
    }

    return transformedColumn;
  });

  /**
   * used for data reset
   */

  const tableColumns = [
    ...transformedColumns,
    {
      title: "Actions",
      key: "action",
      fixed: "right",
      render: (item) =>
        tableActions ? tableActions(item) : renderTableActions(item),
    },
  ];
  const renderTableActions = (item) => {
    return (
      <div className="ui three buttons">
        <Button
          style={styles.tableActionButtons}
          shape="circle"
          onClick={() => {
            updateState({ view: true, mode: "edit", selectedItem: item });
          }}
          icon={<EditTwoTone key="Edit" twoToneColor={"blue"} />}
        />

        <Button
          style={styles.tableActionButtons}
          shape="circle"
          onClick={() => {
            updateState({ view: true, mode: "view", selectedItem: item });
          }}
          icon={<EyeTwoTone key="View" twoToneColor={"green"} />}
        />

        <Popconfirm
          placement="top"
          title="Confirm Delete"
          okText="Yes"
          cancelText="No"
          onConfirm={() => confirmDelete(item)}
        >
          <Button
            style={styles.tableActionButtons}
            shape="circle"
            icon={<DeleteTwoTone key="delete" twoToneColor="red" />}
          />
        </Popconfirm>
      </div>
    );
  };

  const updateData = (newData) => {
    setTableData(newData);
  };
  const resetData = () => updateData(unfilteredData);

  return (
    <div className="tableContainer" style={{ marginBottom: "15%" }}>
      <Row>
        <Col>
          <div className="tableWrapper">
            <Table
              className="gx-table-responsive"
              columns={tableColumns}
              dataSource={tableData}
              {...tableSettings}
              tableLayout="fixed"
              scroll={scroll}
              pagination={pagination}
              onChange={handleTableChange}
              {...rest}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

const styles = {
  icon: {
    fontSize: "20px",
  },
  tableActionButtons: {
    marginLeft: "2%",
    marginRight: "2%",
  },
  tableInnerContainer: {
    margin: "12px 8px",
  },
};
