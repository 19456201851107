import React, { useState, useEffect } from "react";
import {
  Grid,
  Menu,
  Segment,
  Icon,
  Header,
  Breadcrumb,
} from "semantic-ui-react";
import { Button, Popconfirm } from "antd";
import { DeleteTwoTone, EditTwoTone, EyeTwoTone } from "@ant-design/icons";

import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import Toolbar from "../components/common/Toolbar";
import { useAuth } from "../context/AuthContext";
import Swal from "sweetalert2";
import { VaultTable } from "../components/common/VaultTable";
import { AV_MODAL } from "../components/shared/AV_MODAL";
import { AV_NOTIFY } from "../components/shared/AV_NOTIFY";
import CreateEditTransactionForm from "../components/common/transactions/CreateTransactionForm";
// import TransactionDetailsModal from "../components/common/transactions/TransactionDetailsModal";
import ViewTransactionModal from "../components/common/transactions/ViewTransactionModal";
import EditTransactionModal from "../components/common/transactions/EditTransactionModal";

import { ENV } from "../config/env.config";
import "./styles/Projects.css";
import { dateTimeFormat } from "../helpers/date.util";
import { TransactionsService, FileService } from "../services";
const transactionsService = TransactionsService.create();
const fileService = FileService.create();

const baseUrl = ENV.baseUrl;

export const Transactions = () => {
  // Retrieve userId from AuthContext
  const { userData } = useAuth();
  const currentUserId = userData ? userData._id : null;

  const [transactions, setTransactions] = useState([]);
  const [files, setFiles] = useState([]);
  const [currentFiles, setCurrentFiles] = useState([]);

  const [isCreateTransactionModalOpen, setIsCreateTransactionModalOpen] =
    useState(false);
  const [isViewTransactionModalOpen, setIsViewTransactionModalOpen] =
    useState(false);
  const [isEditTransactionModalOpen, setIsEditTransactionModalOpen] =
    useState(false);

  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(false);
  const [transactionToDelete, setTransactionToDelete] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
  const [transactionModalMode, setTransactionModalMode] = useState("create");
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const transactions = await transactionsService.$get();

      const mapped = transactions.data.map((transaction) => {
        console.log("Transaction Here: ", transaction);
        return {
          ...transaction,
          project: transaction.project.name,
          type: transaction.type.name,
        };
      });
      setTransactions(mapped);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchFiles = async () => {
    setLoading(true);
    try {
      const files = await fileService.$get();

      const mapped = files.data.map((file) => {
        return {
          ...file,
          // project: transaction.project.name,
          // type: transaction.type.name,
        };
      });
      setFiles(mapped);
    } catch (error) {
      console.error("Error fetching files:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFiles();
    fetchTransactions();
  }, []);

  // const openTransactionModal = (transaction, mode) => {
  //   setSelectedTransaction(transaction);
  //   const filteredFiles = files.filter(
  //     (file) => file.transaction === transaction._id
  //   );
  //   setCurrentFiles(filteredFiles);
  //   setTransactionModalMode(mode);
  //   setIsTransactionModalOpen(true);
  // };

  const openViewTransactionModal = (transaction, mode) => {
    setSelectedTransaction(transaction);
    const filteredFiles = files.filter(
      (file) => file.transaction === transaction._id
    );
    setCurrentFiles(filteredFiles);
    setTransactionModalMode(mode);
    setIsViewTransactionModalOpen(true);
  };

  const openEditTransactionModal = (transaction, mode) => {
    setSelectedTransaction(transaction);
    const filteredFiles = files.filter(
      (file) => file.transaction === transaction._id
    );
    setCurrentFiles(filteredFiles);
    setTransactionModalMode(mode);
    setIsEditTransactionModalOpen(true);
  };

  // const handleConfirmDelete = async (transaction) => {
  //   const transactions = await transactionsService.$delete(transaction._id);
  //   // const transactions_ = await transactionsService.$patch(transaction._id, {
  //   //   deleted: true,
  //   // });
  //   setTransactionToDelete(transaction._id);

  //   if (transactionToDelete) {
  //     try {
  //       await axios.delete(
  //         `${baseUrl}/transactions/delete-transaction/${transactionToDelete}`
  //       );
  //       setTransactions(
  //         transactions.filter((trans) => trans._id !== transactionToDelete)
  //       );
  //       fetchTransactions();
  //       const Toast = Swal.mixin({
  //         toast: true,
  //         position: "top-end",
  //         showConfirmButton: false,
  //         timer: 2000,
  //         timerProgressBar: true,
  //         didOpen: (toast) => {
  //           toast.onmouseenter = Swal.stopTimer;
  //           toast.onmouseleave = Swal.resumeTimer;
  //         },
  //       });
  //       Toast.fire({
  //         icon: "success",
  //         title: "Transaction deleted",
  //       });
  //     } catch (error) {
  //       console.error("Error deleting transaction:", error);
  //     }
  //   }
  // };
  const handleConfirmDelete = async (transaction) => {
    try {
      await transactionsService.$delete(transaction._id); // Correct call to $delete
      AV_NOTIFY({
        title: "Success",
        content: "Transaction deleted successfully",
        type: "success",
        duration: 3,
      });
      fetchTransactions(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting transaction:", error);
      AV_NOTIFY({
        title: "Error",
        content: "Failed to delete the transaction",
        type: "error",
        duration: 3,
      });
    }
  };

  const handleSearch = (searchTerm) => {
    const filteredResults = transactions.filter((transaction) =>
      transaction.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredResults);
  };

  const actions = (transaction) => {
    return (
      <div className="ui three buttons">
        <Button
          style={styles.tableActionButtons}
          shape="circle"
          onClick={() => openEditTransactionModal(transaction, "edit")}
          icon={<EditTwoTone key="Edit" twoToneColor={"blue"} />}
        />
        <Button
          style={styles.tableActionButtons}
          shape="circle"
          onClick={() => openViewTransactionModal(transaction, "view")}
          icon={<EyeTwoTone key="View" twoToneColor={"green"} />}
        />

        <Popconfirm
          placement="top"
          title="Confirm Delete"
          okText="Yes"
          cancelText="No"
          onConfirm={() => handleConfirmDelete(transaction)}
        >
          <Button
            style={styles.tableActionButtons}
            shape="circle"
            icon={<DeleteTwoTone key="delete" twoToneColor="red" />}
          />
        </Popconfirm>
      </div>
    );
  };

  return (
    <>
      <div className="breadcrumb-container">
        <Breadcrumb>
          <Breadcrumb.Section as={Link} to="/">
            <Icon name="home" />
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section active>Transactions</Breadcrumb.Section>
        </Breadcrumb>
      </div>
      <Grid style={{ padding: "30px", fontFamily: "Jost, sans-serif" }}>
        <Grid.Column width={2}>
          <Menu fluid vertical tabular>
            <Menu.Item
              name="projects"
              as={Link}
              to="/projects"
              active={location.pathname === "/projects"}
            >
              <Icon name="clipboard list" />
              <p style={{ fontFamily: "Jost, sans-serif", fontWeight: "400" }}>
                Projects
              </p>
            </Menu.Item>
            <Menu.Item
              name="transactions"
              active={location.pathname === "/transactions"}
            >
              <Icon name="exchange" />
              <p style={{ fontFamily: "Jost, sans-serif", fontWeight: "400" }}>
                Transactions
              </p>
            </Menu.Item>
            <Menu.Item
              name="users"
              as={Link}
              to="/users"
              active={location.pathname === "/users"}
            >
              <Icon name="users" />
              <p style={{ fontFamily: "Jost, sans-serif", fontWeight: "400" }}>
                Users
              </p>
            </Menu.Item>
          </Menu>
        </Grid.Column>
        <Grid.Column stretched width={14}>
          <Segment>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Header
                as="h2"
                style={{ fontFamily: "Jost, sans-serif", fontWeight: "500" }}
              >
                Autovault Transactions
              </Header>
              <Toolbar
                onRefresh={fetchTransactions}
                onSearch={handleSearch}
                isTransactions={true}
                modalComponent={
                  <AV_MODAL
                    scrolling={false}
                    title={"Create Transaction"}
                    visible={isCreateTransactionModalOpen}
                    onClose={() => {
                      setIsCreateTransactionModalOpen(false);
                    }}
                    handleCancel={() => {
                      setIsCreateTransactionModalOpen(false);
                    }}
                    size={"large"}
                    children={
                      <CreateEditTransactionForm
                        mode={"create"}
                        project={undefined}
                        handleCancel={() => {
                          setIsCreateTransactionModalOpen(false);
                        }}
                      />
                    }
                  />
                }
              />
            </div>
            <VaultTable
              data={transactions}
              tableActions={actions}
              title="Transactions"
              columns={[
                {
                  title: "Project Name",
                  key: "project",
                  dataIndex: "project",
                  searchable: true,
                },
                { title: "Amount (N$)", key: "amount", dataIndex: "amount" },
                { title: "Notes", key: "notes", dataIndex: "notes" },
                {
                  title: "Transaction Type",
                  key: "type",
                  dataIndex: "type",
                  searchable: true,
                },
                {
                  title: "Created",
                  key: "created",
                  dataIndex: "createdAt",
                  render: (createdAt) => dateTimeFormat(new Date(createdAt)),
                },
              ]}
              rowKey="_id"
              scroll={{ x: 100, y: 1000 }}
              paginationSettings={{
                position: "bottomRight",
                current: currentPage,
                pageSize: itemsPerPage,
                total: searchResults.length,
              }}
              loading={loading}
              refetch={fetchTransactions}
              confirmDelete={handleConfirmDelete}
              refreshing={loading}
            />
          </Segment>
        </Grid.Column>
      </Grid>

      <ViewTransactionModal
        isOpen={isViewTransactionModalOpen}
        onClose={() => setIsViewTransactionModalOpen(false)}
        transactionData={selectedTransaction}
        mode={transactionModalMode}
        files={currentFiles}
      />

      <EditTransactionModal
        isOpen={isEditTransactionModalOpen}
        onClose={() => setIsEditTransactionModalOpen(false)}
        transactionData={selectedTransaction}
        files={currentFiles}
      />
    </>
  );
};
const styles = {
  tableActionButtons: {
    marginLeft: "2%",
    marginRight: "2%",
  },
};
