/**
 * transaction-types.service.js
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2024
 * All rights reserved
 */

import { APIService } from '../api/index';

let instance;

export class TransactionTypesService extends APIService {
    constructor() {
        const collection = 'transaction-types';
        super(collection, 'transaction-types');
    }
    static create() {
        if (!instance) {
            instance = new TransactionTypesService();
        }
        return instance;
    }
}
