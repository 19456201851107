import React, { useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import PropTypes from "prop-types"; // Import PropTypes for type checking
import { useAuth } from "../../context/AuthContext";

const Toolbar = ({ onRefresh, onSearch, isTransactions, modalComponent }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const { userData } = useAuth();
  const userId = userData ? userData._id : null;

  const handleSearchChange = (e, { value }) => {
    setSearchTerm(value);
    // You may want to implement debounce here to limit API calls while typing
  };

  const handleSearch = () => {
    if (onSearch) {
      onSearch(searchTerm);
    }
  };

  const handleAddNew = () => {
    setCreateModalOpen(true);
  };

  const handleCloseCreateModal = () => {
    setCreateModalOpen(false);
  };

  const handleRefresh = () => {
    if (onRefresh) {
      onRefresh();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Button
        icon
        basic
        size="mini"
        style={{ marginLeft: "10px" }}
        onClick={handleRefresh}
      >
        Refresh
      </Button>
      {isTransactions ? ( // Check if it's the Transactions component
        <>
          <Button size="mini" color="green" onClick={handleAddNew}>
            <Icon name="add" />
            Add new
          </Button>
          {modalComponent &&
            React.cloneElement(modalComponent, {
              open: isCreateModalOpen,
              onClose: handleCloseCreateModal,
              isNewProject: true,
            })}
        </>
      ) : (
        <>
          <Button size="mini" color="green" onClick={handleAddNew}>
            <Icon name="add" />
            Add new
          </Button>
          {modalComponent &&
            React.cloneElement(modalComponent, {
              open: isCreateModalOpen,
              onClose: handleCloseCreateModal,
              isNewProject: true,
              user: userId,
            })}
        </>
      )}
    </div>
  );
};

Toolbar.propTypes = {
  onRefresh: PropTypes.func, // Function to handle refresh action
  onSearch: PropTypes.func, // Function to handle search action
  isTransactions: PropTypes.bool, // Boolean to determine if it's Transactions component
  modalComponent: PropTypes.element, // Modal component to be rendered dynamically
};

export default Toolbar;
