/**
 * AV_MODAL.jsx
 *
 * @author Erastus Nathingo <contact@erassy.com>
 * @copyright 2024
 * All rights reserved
 */

import React from 'react';
import { Grid, Modal } from 'semantic-ui-react';
import { Typography } from 'antd';

const { Title } = Typography;

export function AV_MODAL(props) {
	const {
		handleCancel,
		visible,
		title = '',
		children,
		size,
		height = 'auto',
		dark = false,
		scrolling = true,
		headerVisible = true,
		customStyles = {},
		...rest
	} = props;

	const styles = {
		modal: {
			backgroundColor: '#0c3a51',
			height,
		},
		header: {
			background: 'transparent',
			backgroundColor: 'transparent',
		},
		headerText: {
			color: '#fff',
		},

		content: {
			backgroundColor: '#fff',
			height: '100%',
		},
		closingButton: {
			color: '#fff',
			fontSize: '20px',
		},
	};

	return (
		<Modal
			open={visible}
			centered={true}
			size={size || 'large'}
			closeOnDimmerClick={false}
			onClose={handleCancel}
			style={{ ...styles.modal, ...customStyles }}
			dimmer={'inverted'}
			{...rest}
		>
			<Modal.Header style={styles.header}>
				<a
					style={styles.closingButton}
					onClick={() => handleCancel()}
					className="modalClose"
				>
					X
				</a>

				<Grid columns={1} relaxed="very" stackable centered>
					<Grid.Row columns={1}>
						{headerVisible && (
							<Title level={3} style={styles.headerText}>
								{title}
							</Title>
						)}
					</Grid.Row>
				</Grid>
			</Modal.Header>
			<Modal.Content scrolling={scrolling} style={styles.content}>
				{children}
			</Modal.Content>
		</Modal>
	);
}
