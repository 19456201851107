import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Form as SemanticForm,
  Message,
  Icon,
} from "semantic-ui-react";
import { AV_INPUT } from "../components/shared/AV_INPUT";
import { ENV } from "../config/env.config";
import "./styles/Login.css";

const baseUrl = ENV.baseUrl;

export const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(`${baseUrl}/users/login`, values);

      const userData = response.data.user;

      // Call the login function with the token
      login(response.data.token, userData);

      navigate("/");
    } catch (error) {
      console.error(
        "Login failed:",
        error.response ? error.response.data : error
      );

      setErrorMessage("Invalid login credentials");
    }
    setIsSubmitting(false);
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <Grid centered columns={1}>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <div className="login-form">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values, setSubmitting);
              }}
            >
              {({
                handleSubmit,
                errors,
                touched,
                handleChange,
                handleBlur,
                values,
              }) => (
                <SemanticForm onSubmit={handleSubmit}>
                  <SemanticForm.Group>
                    <AV_INPUT
                      name="email"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      label="Email"
                      value={values.email}
                      type="email"
                      errors={errors}
                      touched={touched}
                    />
                  </SemanticForm.Group>

                  <SemanticForm.Group>
                    <div style={{ position: "relative", width: "100%" }}>
                      <AV_INPUT
                        name="password"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        label="Password"
                        value={values.password}
                        type={showPassword ? "text" : "password"}
                        icon={
                          <Icon
                            name={showPassword ? "eye slash" : "eye"}
                            link
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        }
                        fluid
                        error={
                          touched.password && errors.password ? true : false
                        }
                      />
                      {touched.password && errors.password && (
                        <div className="ui pointing red basic label">
                          {errors.password}
                        </div>
                      )}
                    </div>
                  </SemanticForm.Group>

                  <SemanticForm.Group>
                    <div className="message-container">
                      {errorMessage && (
                        <Message
                          negative
                          size="tiny"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {errorMessage}
                        </Message>
                      )}
                    </div>
                  </SemanticForm.Group>

                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    primary
                    fluid
                    style={{ backgroundColor: "var(--primary-color)" }}
                  >
                    {isSubmitting ? "Logging in..." : "Login"}
                  </Button>
                </SemanticForm>
              )}
            </Formik>
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
};
